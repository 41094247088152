import "core-js/modules/es.array.push.js";
import EditAddressPopup from '../../common/EditAddressPopup.vue';
import { orderList, orderCancel } from '../../../api/order.js';
import Navigation from '../../common/Navigation.vue';
import PaymentChannelPopup from '../../common/PaymentChannelPopup.vue';
import { Button, Popup } from 'vant';
export default {
  name: 'OrderList',
  components: {
    EditAddressPopup,
    PaymentChannelPopup,
    Navigation,
    [Button.name]: Button,
    [Popup.name]: Popup
  },
  data() {
    return {
      cancelOrderNum: '',
      cancelOrderShow: false,
      currencyCode: '',
      totalFee: Number,
      // 修改地址弹窗是否展示
      editAddressFlag: false,
      // 给修改地址弹窗子组件的传值
      oldAddress: {},
      orderId: Number,
      orderList: [],
      // 订单的几个状态,从生产到完成
      orderProcessList: [],
      list: [],
      loading: false,
      finished: false,
      paymentShow: false
    };
  },
  created: async function () {},
  methods: {
    goToSelectProduct() {
      localStorage.setItem('goWhere', 'productList');
      this.$router.push({
        path: '/home'
      });
    },
    goToPreview(orderProductId, orderId, productType) {
      const backUrl = window.location.pathname;
      // eslint-disable-next-line eqeqeq
      if (productType == 1) {
        this.$router.push({
          path: '/order/preview/commonCustom',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
        // eslint-disable-next-line eqeqeq
      } else if (productType == 3) {
        console.log('go to preview   3');
        this.$router.push({
          path: '/order/preview/photoBook',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
        // eslint-disable-next-line eqeqeq
      } else if (productType == 2) {
        console.log('go to preview   2');
        this.$router.push({
          path: '/order/preview/even_photos',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
      } else if (productType == 4) {
        console.log('go to preview');
        this.$router.push({
          path: '/order/preview/free_custom',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
      }
    },
    callbackForClose() {
      if (this.paymentShow) {
        this.paymentShow = false;
      }
    },
    onLoad() {
      // 异步更新数据
      orderList().then(res => {
        this.orderList = res.data.data.list;
        // 加载状态结束
        this.loading = false;
        this.finished = true;
      });
    },
    goToOrderDetail(orderId) {
      this.$router.push({
        path: '/order/detail',
        query: {
          orderId: orderId
        }
      });
    },
    onClickGoPay(orderId, totalFee, currencyCode) {
      this.totalFee = totalFee;
      this.currencyCode = currencyCode;
      this.paymentShow = !this.paymentShow;
      this.orderId = orderId;
    },
    onClickCancel(orderId) {
      this.cancelOrderShow = true;
      this.cancelOrderNum = orderId;
    },
    confirmCancelOrder() {
      const data = {
        orderId: this.cancelOrderNum
      };
      orderCancel(data).then(res => {
        res;
        this.onLoad();
        this.cancelOrderShow = false;
      });
    },
    cancelCancelOrder() {
      this.cancelOrderShow = false;
    },
    // 修改订单地址
    onClickEditAddress(item) {
      this.oldAddress = item;
      this.editAddressFlag = false;
      this.editAddressFlag = true;
      this.addAddressFlag = false;
    },
    // 确定修改地址回调
    callbackForEdit() {
      this.editAddressFlag = false;
      // this.onLoad()
    },

    // 取消修改地址回调
    callbackForCancelEdit() {
      this.editAddressFlag = false;
    },
    onClickSigned() {}
  }
};