var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "height": "100vh",
      "background-color": "#EFEFEF"
    }
  }, [_c('navigation'), _vm.orderList.length == 0 ? [_c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "20vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v("no data")]), _c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "30vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('van-button', {
    staticStyle: {
      "width": "49.49vw",
      "height": "10.77vw",
      "background-color": "#D56460",
      "border-radius": "1.56vw",
      "color": "aliceblue",
      "font-weight": "bold"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSelectProduct();
      }
    }
  }, [_vm._v("Select Product")])], 1)] : _vm._e(), _vm.orderList.length > 0 ? _c('div', _vm._l(_vm.orderList, function (order) {
    return _c('div', {
      key: order.id,
      staticStyle: {
        "background-color": "#FFE9EC",
        "width": "94.88vw",
        "height": "50.82vw",
        "margin-top": "2vw",
        "margin-left": "2.56vw",
        "border-radius": "1.56vw"
      },
      on: {
        "click": function ($event) {
          return _vm.goToOrderDetail(order.id);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "font-size": "3.59vw",
        "width": "91.88vw",
        "margin-left": "3vw",
        "height": "3vw"
      }
    }), _c('div', {
      staticStyle: {
        "font-size": "3.59vw",
        "width": "91.88vw",
        "margin-left": "3vw",
        "height": "6vw"
      }
    }, [_vm._v(" OrderSn: " + _vm._s(order.orderNum) + " ")]), _c('div', {
      staticStyle: {
        "background-color": "#FFFFFF",
        "width": "94.88vw",
        "height": "32.05vw",
        "border-radius": "1.56vw"
      }
    }, [_c('van-image', {
      staticStyle: {
        "margin-top": "3.2vw",
        "margin-left": "3.2vw",
        "display": "inline-block",
        "width": "25.64vw",
        "height": "25.64vw",
        "border-radius": "1.56vw",
        "overflow": "hidden"
      },
      attrs: {
        "src": order.products[0].coverImg
      }
    }), _c('div', {
      staticStyle: {
        "margin-top": "3.2vw",
        "margin-left": "3.2vw",
        "display": "inline-block",
        "width": "45.64vw",
        "height": "25.64vw",
        "overflow": "hidden"
      }
    }, [_c('div', {
      staticStyle: {
        "font-size": "3.08vw",
        "font-weight": "bold",
        "display": "flex",
        "align-items": "center"
      }
    }, [_vm._v(_vm._s(order.products[0].productName))]), _c('div', {
      staticStyle: {
        "height": "6.41vw",
        "font-size": "3.08vw",
        "color": "#BBBBBB",
        "display": "flex",
        "align-items": "center"
      }
    }, [_vm._v(_vm._s(order.products[0].skuName))]), _c('div', {
      staticStyle: {
        "margin-top": "8vw",
        "height": "6.41vw",
        "font-size": "3.08vw"
      }
    })]), _c('div', {
      staticStyle: {
        "margin-top": "3.2vw",
        "display": "inline-block",
        "width": "16.64vw",
        "height": "25.64vw",
        "border-radius": "1.56vw",
        "overflow": "hidden"
      }
    }, [_c('van-button', {
      staticStyle: {
        "font-size": "3.08vw",
        "border": "solid 0.3vw #BBBBBB",
        "width": "15.9vw",
        "height": "5.9vw",
        "border-radius": "1.56vw"
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.goToPreview(order.products[0].id, order.products[0].orderId, order.products[0].baseInfo.type);
        }]
      }
    }, [_vm._v(" Preview ")])], 1)], 1), _c('div', {
      staticClass: "order-list-item-btn-box",
      staticStyle: {
        "background-color": "#FFFFFF",
        "margin-top": "1vw",
        "width": "94.88vw",
        "height": "8.05vw",
        "border-radius": "1.56vw",
        "align-items": "center"
      }
    }, [order.orderBtn.canShowPay ? _c('van-button', {
      staticStyle: {
        "background-color": "#D56460",
        "border-radius": "1.56vw",
        "margin-left": "1vw",
        "color": "#EFEFEF",
        "font-size": "3.59vw",
        "font-weight": "bold",
        "width": "26.41vw",
        "margin-top": "1vw",
        "float": "right",
        "height": "6.05vw"
      },
      attrs: {
        "size": "mini",
        "round": "",
        "plain": "",
        "type": "danger"
      },
      on: {
        "click": [function ($event) {
          return _vm.onClickGoPay(order.id, order.realAmount, order.currencyCode);
        }, function ($event) {
          $event.stopPropagation();
        }]
      }
    }, [_vm._v("Pay")]) : _vm._e(), order.orderBtn.canShowCancel ? _c('van-button', {
      staticStyle: {
        "border": "solid 0.3vw #BBBBBB",
        "border-radius": "1.56vw",
        "font-size": "3.59vw",
        "width": "26.41vw",
        "margin-top": "1vw",
        "float": "right",
        "height": "6.05vw"
      },
      attrs: {
        "size": "mini",
        "round": "",
        "plain": "",
        "type": "default"
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.onClickCancel(order.id);
        }]
      }
    }, [_vm._v("Cancel")]) : _vm._e(), order.orderBtn.canShowSign ? _c('van-button', {
      staticStyle: {
        "border": "solid 0.3vw #BBBBBB",
        "border-radius": "1.56vw",
        "font-size": "3.59vw",
        "width": "26.41vw",
        "margin-top": "1vw",
        "float": "right",
        "height": "6.05vw"
      },
      attrs: {
        "size": "mini",
        "round": "",
        "plain": "",
        "type": "default"
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.onClickEditAddress(order.addressInfo);
        }]
      }
    }, [_vm._v("confirm deliver")]) : _vm._e(), order.orderBtn.canShowChangeAddress ? _c('van-button', {
      staticStyle: {
        "border": "solid 0.3vw #BBBBBB",
        "border-radius": "1.56vw",
        "font-size": "3.59vw",
        "width": "32.41vw",
        "margin-top": "1vw",
        "float": "right",
        "height": "6.05vw"
      },
      attrs: {
        "size": "mini",
        "round": "",
        "plain": "",
        "type": "default"
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.onClickEditAddress(order.addressInfo);
        }]
      }
    }, [_vm._v("Change Address")]) : _vm._e()], 1)]);
  }), 0) : _vm._e(), _c('van-list', {
    staticStyle: {},
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.onLoad
    }
  }), _c('payment-channel-popup', {
    attrs: {
      "currency-code": _vm.currencyCode,
      "order-id": _vm.orderId,
      "popup": _vm.paymentShow,
      "total-fee": _vm.totalFee
    },
    on: {
      "callbackForClose": _vm.callbackForClose
    }
  }), _vm.editAddressFlag ? _c('edit-address-popup', {
    attrs: {
      "old-address": _vm.oldAddress,
      "edit-address-popup-show": _vm.editAddressFlag
    },
    on: {
      "callbackForEdit": _vm.callbackForEdit,
      "callbackForCancelEdit": _vm.callbackForCancelEdit
    }
  }) : _vm._e(), _c('van-popup', {
    staticStyle: {
      "width": "90.87vw",
      "height": "45.87vw",
      "border-radius": "2.56vw",
      "overflow": "hidden"
    },
    model: {
      value: _vm.cancelOrderShow,
      callback: function ($$v) {
        _vm.cancelOrderShow = $$v;
      },
      expression: "cancelOrderShow"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-top": "5vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "90.87vw",
      "height": "10vw",
      "font-size": "5vw",
      "font-weight": "bold",
      "color": "#BD3124"
    }
  }, [_vm._v("The operation cannot be withdrawn,")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "90.87vw",
      "height": "10vw",
      "font-size": "5vw",
      "font-weight": "bold",
      "color": "#BD3124"
    }
  }, [_vm._v("whether to continue operating ?")]), _c('div', [_c('van-button', {
    staticStyle: {
      "margin-left": "10vw",
      "background-color": "#BD3124",
      "border": "0px",
      "border-radius": "1.56vw",
      "color": "#EFEFEF",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "width": "29.49vw",
      "margin-top": "5vw",
      "height": "8.46vw"
    },
    attrs: {
      "round": "",
      "plain": "",
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmCancelOrder();
      }
    }
  }, [_vm._v("Yes")]), _c('van-button', {
    staticStyle: {
      "margin-left": "10vw",
      "background-color": "#8DC787",
      "border": "0px",
      "border-radius": "1.56vw",
      "color": "#EFEFEF",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "width": "29.49vw",
      "margin-top": "5vw",
      "height": "8.46vw"
    },
    attrs: {
      "round": "",
      "plain": "",
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.cancelCancelOrder();
      }
    }
  }, [_vm._v("No")])], 1)])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };