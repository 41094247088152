var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "aaa"
    }
  }, [_c('van-popup', {
    style: {
      width: '85vw'
    },
    attrs: {
      "before-close": _vm.aaa(),
      "round": ""
    },
    model: {
      value: _vm.editAddressPopupShow,
      callback: function ($$v) {
        _vm.editAddressPopupShow = $$v;
      },
      expression: "editAddressPopupShow"
    }
  }, [_c('div', {
    staticStyle: {
      "font-weight": "bold",
      "height": "14vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v("Edit Address")]), _c('van-divider', {
    staticStyle: {
      "margin": "0px",
      "background-image": "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)"
    }
  }), _c('br'), _c('van-form', {
    on: {
      "submit": _vm.onAddressEditSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "name": "country",
      "value": _vm.addressForm.country,
      "label": "Country",
      "placeholder": "Select Country First     >  ",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    on: {
      "click": function ($event) {
        _vm.countrySelectShow = true;
      }
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.countrySelectShow,
      callback: function ($$v) {
        _vm.countrySelectShow = $$v;
      },
      expression: "countrySelectShow"
    }
  }, [_c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "confirm-button-text": "Confirm",
      "cancel-button-text": "Cancel",
      "columns": _vm.countryList
    },
    on: {
      "confirm": _vm.onCountrySelected,
      "cancel": function ($event) {
        _vm.countrySelectShow = false;
      }
    }
  })], 1), _c('van-field', {
    attrs: {
      "name": "firstName",
      "label": "First name",
      "placeholder": "First name",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "firstName", $$v);
      },
      expression: "addressForm.firstName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "lastName",
      "label": "Last name",
      "placeholder": "Last name",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "lastName", $$v);
      },
      expression: "addressForm.lastName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "address_1",
      "label": "Address_1",
      "placeholder": "Address_1",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.address_1,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "address_1", $$v);
      },
      expression: "addressForm.address_1"
    }
  }), _c('van-field', {
    attrs: {
      "name": "address_2",
      "label": "Address_2",
      "placeholder": "Address_2（optional）"
    },
    model: {
      value: _vm.addressForm.address_2,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "address_2", $$v);
      },
      expression: "addressForm.address_2"
    }
  }), _c('van-field', {
    attrs: {
      "name": "city",
      "label": "City",
      "placeholder": "City",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.city,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "city", $$v);
      },
      expression: "addressForm.city"
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "name": "state",
      "value": _vm.addressForm.areaPath,
      "rules": [{
        required: true,
        message: ''
      }],
      "label": "State",
      "placeholder": "Select State     >  "
    },
    on: {
      "click": function ($event) {
        return _vm.areaSelectShowChange();
      }
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.areaSelectShow,
      callback: function ($$v) {
        _vm.areaSelectShow = $$v;
      },
      expression: "areaSelectShow"
    }
  }, [_vm.areaSelectShow ? _c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "confirm-button-text": "Confirm",
      "cancel-button-text": "Cancel",
      "columns": _vm.columns,
      "item-height": 35
    },
    on: {
      "cancel": function ($event) {
        _vm.areaSelectShow = false;
      },
      "confirm": _vm.onAreaSelect,
      "change": _vm.onChange
    }
  }) : _vm._e()], 1), _c('van-field', {
    attrs: {
      "name": "postcode",
      "label": "ZipCode",
      "placeholder": "ZipCode",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "postcode", $$v);
      },
      expression: "addressForm.postcode"
    }
  }), _c('van-field', {
    attrs: {
      "name": "phone",
      "label": "Phone",
      "placeholder": "Phone",
      "rules": [{
        required: true,
        message: ''
      }]
    },
    model: {
      value: _vm.addressForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "phone", $$v);
      },
      expression: "addressForm.phone"
    }
  }), _c('van-field', {
    attrs: {
      "name": "email",
      "label": "Email",
      "placeholder": "Email（optional）"
    },
    model: {
      value: _vm.addressForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.addressForm, "email", $$v);
      },
      expression: "addressForm.email"
    }
  }), _c('div', {
    staticStyle: {
      "margin": "16px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit",
      "color": "red"
    }
  }, [_vm._v("save")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };