import "core-js/modules/es.array.push.js";
import { Button, Field, Form, Popup, Picker, Icon } from 'vant';
import { areaAvailableCountries, areaAvailableCountriesDetail } from '../../api/common';
import { addressUpdate } from '../../api/user';
import { orderChangeAddress } from '../../api/order';
export default {
  name: 'AddAddressPopup',
  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
    [Picker.name]: Picker,
    [Icon.name]: Icon
  },
  props: {
    // 接收的父组件的老地址的值
    // eslint-disable-next-line vue/require-default-prop
    oldAddress: {
      type: Object
    },
    // 接收的父组件传值,控制弹窗是否展示
    editAddressPopupShow: {
      type: Boolean
    }
  },
  data() {
    return {
      newAddress: {
        consignee: '',
        firstName: '',
        lastName: '',
        countryId: Number,
        stateId: Number,
        phone: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        postCode: '',
        email: ''
      },
      countryAll: '',
      provinceData: '',
      cityDates: '',
      // columns 为三个，即渲染出来的就是三级联动。
      columns: [{
        values: '',
        className: 'column1'
      }],
      addressForm: {
        firstName: '',
        lastName: '',
        country: '',
        areaPath: '',
        city: '',
        phone: '',
        address_1: '',
        address_2: '',
        postcode: '',
        email: '',
        postalCode: ''
      },
      countryList: [],
      countrySelectShow: false,
      uploadPictureShow: false,
      areaSelectShow: false,
      // 省市区列表
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      },
      stateId: Number,
      countryId: Number
    };
  },
  created: async function () {
    this.putOldAddress(JSON.stringify(this.oldAddress));
    this.getCountryList();
    this.onCountrySelected(this.oldAddress.country);
  },
  methods: {
    areaSelectShowChange() {
      if (this.addressForm.country) {
        this.areaSelectShow = true;
      }
    },
    aaa() {
      if (this.editAddressPopupShow) {
        return false;
      }
      this.$emit('callbackForCancelEdit');
    },
    // 展示编辑前的数据
    putOldAddress(addressInfo) {
      const oldAddress = JSON.parse(addressInfo);

      // 给输入框显示值
      this.addressForm.firstName = oldAddress.extra.firstName;
      this.addressForm.lastName = oldAddress.extra.lastName;
      this.addressForm.country = oldAddress.country;
      this.addressForm.areaPath = oldAddress.state;
      this.addressForm.phone = oldAddress.phone;
      this.addressForm.city = oldAddress.city;
      this.addressForm.address_1 = oldAddress.addressLine1;
      this.addressForm.address_2 = oldAddress.addressLine2;
      this.addressForm.postcode = oldAddress.postalCode;
      this.addressForm.email = oldAddress.extra.email;
      // 给接口的参数值,就给原来的值

      this.newAddress.addressId = oldAddress.id;
      this.newAddress.consignee = oldAddress.consignee;
      this.newAddress.addressLine1 = oldAddress.addressLine1;
      this.newAddress.addressLine2 = oldAddress.addressLine2;
      this.newAddress.postCode = oldAddress.postCode;
      this.newAddress.countryId = oldAddress.extra.countryId;
      this.newAddress.stateId = oldAddress.extra.stateId;
    },
    // 国家选择
    onCountrySelected(value) {
      // eslint-disable-next-line eqeqeq
      if (this.addressForm.country != value) {
        // todo,需要加一个判断,国家变化了才变为"",还要加上地区选择联动
        this.addressForm.country = value;
        this.addressForm.areaPath = '';
        this.newAddress.stateId = '';
        // 只有重新选择了国家,才需要修改国家id
        areaAvailableCountries().then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            // eslint-disable-next-line eqeqeq
            if (res.data.data[i].name == value) {
              this.newAddress.countryId = res.data.data[i].id;
            }
          }
        });
      }
      this.countrySelectShow = false;
      this.getAreaConfig(value);
    },
    // 地址select选择
    onAreaSelect(values) {
      this.addressForm.areaPath = values.filter(item => !!item).map(item => item.text).join('/');
      // 给add地址接口的参数赋值
      this.newAddress.stateId = values[0].id;
      this.areaSelectShow = false;
    },
    // 提交新的地址
    onAddressEditSubmit(values) {
      this.addressForm.consignee = values.consignee;
      this.addressForm.country = values.country;
      this.addressForm.areaPath = values.area;
      this.addressForm.phone = values.phone;
      this.addressForm.address_1 = values.address_1;
      this.addressForm.address_2 = values.address_2;
      this.addressForm.postcode = values.postcode;
      this.addressForm.city = values.city;
      this.addressForm.email = values.email;
      // 根据oldAddress是否包含orderId来判断是修改地址还是修改订单地址
      // eslint-disable-next-line eqeqeq
      if (this.oldAddress.orderId != undefined) {
        this.editOrderAddress();
      } else {
        this.editAddress();
      }
    },
    editOrderAddress() {
      this.newAddress.consignee = this.addressForm.firstName + ' ' + this.addressForm.lastName;
      this.newAddress.firstName = this.addressForm.firstName;
      this.newAddress.lastName = this.addressForm.lastName;
      this.newAddress.phone = this.addressForm.phone;
      this.newAddress.addressLine1 = this.addressForm.address_1;
      this.newAddress.addressLine2 = this.addressForm.address_2;
      this.newAddress.addressLine2 = this.addressForm.address_2;
      this.newAddress.postalCode = this.addressForm.postcode;
      this.newAddress.city = this.addressForm.city;
      this.newAddress.email = this.addressForm.email;
      this.newAddress.orderId = this.oldAddress.orderId;
      orderChangeAddress(this.newAddress).then(res => {
        res;
        this.$emit('callbackForEdit');
      });
    },
    editAddress() {
      this.newAddress.consignee = this.addressForm.firstName + ' ' + this.addressForm.lastName;
      this.newAddress.firstName = this.addressForm.firstName;
      this.newAddress.lastName = this.addressForm.lastName;
      this.newAddress.phone = this.addressForm.phone;
      this.newAddress.addressLine1 = this.addressForm.address_1;
      this.newAddress.addressLine2 = this.addressForm.address_2;
      this.newAddress.addressLine2 = this.addressForm.address_2;
      this.newAddress.postalCode = this.addressForm.postcode;
      this.newAddress.city = this.addressForm.city;
      this.newAddress.email = this.addressForm.email;
      addressUpdate(this.newAddress).then(res => {
        res;
        this.$emit('callbackForEdit');
      });
    },
    getCountryList() {
      areaAvailableCountries().then(res => {
        this.countryAll = res.data;
        // 修改国家id,根据default接口返回的国家信息修改
        this.countryId = res.data.data[0].id;
        for (let i = 0; i < res.data.data.length; i++) {
          this.countryList.push(res.data.data[i].name);
        }
      });
    },
    getAreaConfig(countryName) {
      areaAvailableCountriesDetail(countryName).then(res => {
        this.provinceData = res.data.data;
        // 默认展示一级的数据
        this.columns[0].values = Object.values(res.data.data).map(function (e) {
          return {
            text: e.name,
            id: e.id
          };
        });
        // // 默认展示二级的数据
        // this.columns[1].values = Object.values(res.data.data[0].cityList).map(function(e) {
        //   return { text: e.name, id: e.id }
        // })
        // // 默认展示三级的数据
        // this.columns[2].values = Object.values(res.data.data[0].cityList[0].countyList).map(function(e) {
        //   return { text: e.name, id: e.id }
        // })
        // 等待dom渲染完毕之后，在去显示 三级联动。
        this.$nextTick(function () {
          this.pageShow = true;
        });
      });
    },
    // vant picker选择器回调
    onChange(picker, values, index) {
      // 这里我有可能渲染的有问题，导致回调每次都修改了当前列，其他值没有修改，当前列？？（当前列什么鬼呀，什么垃圾啊？？？） 解释一下 ↓↓
      // 因为vant的 van-picker 回调只会返回你修改的那一列，比如现在为 [北京，北京，东城区]，你修改了省为[天津市，天津市，和平区]，但是vant的change回调会得到[天津市,北京,东城区],后面两个在回调中没有修改，不知道我的渲染方法有问题还是什么问题。
      // 所以我在这里判断change事件触发后，如果你修改了省份的话，回调里面省份会改变，但是市和区还是上一个省份的 市和区，这时我们执行回调修改省，市，区的时候，因为回调只有省改变了，市和区DOM改变了，但是回调里面没改变的问题（不晓得什么问题）,这时我们去找省下面的 市，我们取回调的市名称，如果在省下面没有找到这个市，默认展示第一个市，区也默认展示第一个市下面的区，如果遍历市能查到，就去展示对应的市。
      // console.log(picker, values, index)
      // // 回调时修改第2列数据
      // picker.setColumnValues(1, this.cityDate(this.provinceData, values[0].text))
      // // 回调时修改第3列数据
      // picker.setColumnValues(2, this.county(this.provinceData, values[1].text))
    }
    // 修改市 这里不再多说什么了根据自己的数据格式来
    // cityDate(data, province) {
    //   var that = this
    //   data.forEach(function(res) {
    //     // eslint-disable-next-line eqeqeq
    //     if (res.name == province) {
    //       console.log(res)
    //       that.cityDates = res
    //       console.log(res)
    //     }
    //   })
    //   return that.cityDates.cityList.map(function(res) {
    //     return { text: res.name, id: res.id }
    //   })
    //   // return 返回数据格式，因为我需要省市区code，所以我return的格式是对象,例:[{text:'北京市',code:'1'},{text:'河南省',code:'2'},...],如果你不需要code直接这样就可以 例['北京市','河南省',.....]
    // },

    // 修改县 这里不再多说什么了根据自己的数据格式来
    // county(data, county) {
    //   var that = this
    //   var countyDate = ''
    //   // 因为vant的 van-picker 回调只会返回你修改的那一列，比如现在为 [北京，北京，东城区]，你修改了省为[天津市，天津市，和平区]，但是vant的change回调会得到[天津市,北京,东城区],后面两个在回调中没有修改，不知道我的渲染方法有问题还是什么问题。
    //   // 所以我在这里判断change事件触发后，如果你修改了省份的话，回调里面省份会改变，但是市和区还是上一个省份的 市和区，这时我们执行回调修改省，市，区的时候，因为回调只有省改变了，市和区DOM改变了，但是回调里面没改变的问题（不晓得什么问题）,这时我们去找省下面的 市，我们取回调的市名称，如果在省下面没有找到这个市，默认展示第一个市，区也默认展示第一个市下面的区，如果遍历市能查到，就去展示对应的市。
    //   that.cityDates.cityList.forEach(function(res) {
    //     // eslint-disable-next-line eqeqeq
    //     if (res.name == county) {
    //       countyDate = res
    //     }
    //   })
    //   // 这里如果没有找到对应的县
    //   // eslint-disable-next-line eqeqeq
    //   if (countyDate == '') {
    //     countyDate = that.cityDates.cityList[0]
    //   }
    //   // return 县
    //   return countyDate.countyList.map(function(res) {
    //     return { text: res.name, id: res.id }
    //   })
    //   // return 返回数据格式，因为我需要省市区code，所以我return的格式是对象,例:[{text:'北京市',code:'1'},{text:'河南省',code:'2'},...],如果你不需要code直接这样就可以 例['北京市','河南省',.....]
    // }

    // deleteAddress() {
    //   let addressId= {
    //     addressId : this.addressList[this.index].id
    //   }
    //   addressDel(addressId).then(res => {
    //     res;
    //     this.$emit('callbackForEdit');
    //   })
    // },
    // submitAndDefault() {
    //   let addressId= {
    //     addressId : this.addressList[this.index].id
    //   }
    //   addressSetDefault(addressId).then(res => {
    //     res;
    //     this.onAddressEditSubmit;
    //   });

    // }
  }
};